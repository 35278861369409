// src/scss/_floating-ghost.scss

@keyframes ghost-animation {
  0% {
    transform: translate(0, 0) rotate(0deg);
    opacity: 0.3;
  }
  25% {
    transform: translate(15px, -25px) rotate(5deg);
    opacity: 0.7;
  }
  50% {
    transform: translate(25px, 10px) rotate(-5deg);
    opacity: 0.5;
  }
  75% {
    transform: translate(-10px, 25px) rotate(8deg);
    opacity: 0.8;
  }
  100% {
    transform: translate(0, 0) rotate(0deg);
    opacity: 0.3;
  }
}

.icon-container {
  width: 200px;
  height: 200px;
  margin-bottom: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.floating-icon {
  font-size: 100px; // Adjust size as needed
  @include theme-aware('color', $text-color, $light-text-color);
  animation: ghost-animation 10s ease-in-out infinite;
  
  i {
    display: inline-block; // Needed for rotation
  }
}

.congratulations-container {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  opacity: 0;
  transition: opacity 0.5s ease-in-out;
  z-index: 1000;

  &.visible {
    opacity: 1;
  }

  h1 {
    font-size: 2.5rem;
    margin-bottom: 1rem;
  }

  p {
    font-size: 1.2rem;
    margin-bottom: 1rem;
    max-width: 80%;
  }

  .button-link {
    margin-top: 2rem;
    padding: 10px 20px;
    background-color: #1877f2;
    color: #fff;
    text-decoration: none;
    border-radius: 5px;
    transition: background-color 0.3s ease;
    cursor: pointer;

    &:hover {
      background-color: #166fe5;
      cursor: pointer;
    }
  }

  .close-button {
    @include button-style;
    margin-top: 1rem;
  }
}